import 'twin.macro';
import React from 'react';
import { navigate } from 'gatsby';

import Seo from '../components/Seo';
import Layout from '../components/Layout';
import Paragraph from '../components/atoms/Paragraph';
import Heading from '../components/atoms/Heading';
import RequestQuoteForm from '../components/forms/RequestQuoteForm';
import config from '../utils/config';

const RequestQuotePage = () => {
  // eslint-disable-next-line consistent-return
  const handleSubmit = async (formData) => {
    const mailReqData = {
      sender: {
        name: formData.name,
        email: formData.email,
      },
      subject: 'New quote from your profield website',
      templateId: 4,
      to: [
        {
          email: 'startso2021@gmail.com',
          name: config.siteName,
        },
      ],
      params: {
        name: formData.name,
        email: formData.email,
        telephone: formData.telephone,
        service: formData.service,
        details: formData.message,
      },
    };

    try {
      await fetch('https://api.sendinblue.com/v3/smtp/email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'api-key': config.SEND_IN_BLUE_API_KEY,
        },
        body: JSON.stringify(mailReqData),
      }).then(() => {
        navigate('/thankyou', { replace: true });
      });
    } catch (err) {
      console.log('err', err);
      alert('Something went wrong, Please try again later.');
      return true;
    }
  };

  return (
    <Layout>
      <Seo title="Request Quote" />
      <div tw="w-full md:py-20 bg-center bg-cover bg-no-repeat">
        <div tw="container mx-auto px-4 flex flex-wrap items-center justify-center gap-10">
          <div tw="w-full md:w-5/12">
            <Heading type="h1">
              Connect with Our Experts for robust Solutions
            </Heading>
            <div tw="mt-6">
              <Paragraph>Help us know your business</Paragraph>
              <div tw="text-gray-500 mt-5">
                <a href={`mailto:${config.email}`}>📧 {config.email}</a>
              </div>
              <div tw="text-gray-500 mt-5">
                <a href={`tel:${config.telephone}`}>📞 {config.telephone}</a>
              </div>
            </div>
          </div>
          <div tw="md:w-6/12 bg-secondary px-8 py-16 flex flex-col lg:ml-auto w-full rounded-md">
            <div tw="max-w-lg mx-auto">
              <RequestQuoteForm onSubmit={handleSubmit} />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};
export default RequestQuotePage;
