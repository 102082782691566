import 'twin.macro';
import React from 'react';

const InputGroup = ({ label, error, placeholder, options, ...otherProps }) => (
  <div tw="relative mb-5">
    <select
      {...otherProps}
      tw=" w-full bg-white rounded border border-tertiary focus:border-tertiary focus:outline-none text-sm outline-none text-primary py-3 px-2 outline-none leading-8 transition-colors duration-150 ease-in-out"
    >
      <option selected value="">
        {placeholder}
      </option>
      {options.map((item) => (
        <option value={item}>{item}</option>
      ))}
    </select>
    {error && <p tw="text-xs text-secondary mt-2">{error}</p>}
  </div>
);
export default InputGroup;
